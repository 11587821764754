<template>
  <div class="home">
    <div class="row justify-center items-center" style="background:#c2dd5d">
      <div class="row justify-center items-center " style="max-width: 1500px">
        <div class="col-12 col-md-6">
            <img alt="Vue logo" style="width:100%" src="../assets/kk-logo.png">
        </div>
        <div class="col-12 col-md-6">
          <!-- eslint-disable max-len -->

          <div class=""
          style="width:100%; padding: 2rem">
            <div class="text-h5" align="center">
              Herzlich Willkommen auf meiner Homepage
            </div>
            <div class="text-h5" align="center">
                              "Künstlerkinder"
            </div><br><br>
            <div class="text-body1" align="center">
            Wollt ihr kreativ sein?<br>
            Macht euch Tanzen Spaß?<br>
            Braucht ihr etwas Entspannung?<br>
            <br>
            Dann seid ihr hier genau richtig!<br>
            <br>
            Ich freue mich schon auf ein buntes Abenteuer!<br>
            <br>
            Eure Jessy
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- Generated by https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections -->
    <div style="height: 150px; overflow: hidden; margin-bottom: -1.5rem;
    z-index:3; position:relative; background:#c2dd5d" >
      <svg viewBox="0 0 500 150" preserveAspectRatio="none"
      style="height: 100%; width: 100%; filter: drop-shadow( 4px -3px 2px rgba(0, 0, 0, .2))">
        <path
        d="M0.00,49.98 C149.99,150.00 350.85,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
        style="stroke: none; fill: #FBFFEB"></path>
      </svg>
    </div>
    <div style="background:#FBFFEB; z-index:3; position:relative">
      <div class="row justify-center">
        <div class="col" style="max-width:1024px">
          <div class="text-subtitle1" align="center">
            Der künstlerische Bereich und kreative Angebote spielen in der kindlichen Entwicklung eine wichtige Rolle.<br>
            <br>
            Nachfolgend findet ihr Termine zu verschiedenen Workshops, zu denen euch eure Eltern per E-Mail unter
            <a href="mailto:kuenstlerkinder@web.de" style="color: black !important;"> kuenstlerkinder@web.de</a> anmelden können.<br>
            <br>
            Die Kurse finden in 61169 Friedberg/Dorheim statt.<br>
            Weitere Infos folgen bei Anmeldung per E-Mail.
          </div>
        </div>
      </div>
      <hr>
      <h3 class="text-center">T e r m i n e</h3>
      <ListMeetings />
      <NewMeeting v-if="auth" />
    </div>
      <!-- eslint-enable max-len -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import ListMeetings from '@/components/ListMeetings.vue';
import NewMeeting from '@/components/NewMeeting.vue';

// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Home',
  components: {
    ListMeetings,
    NewMeeting,
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    // myUploader(event) {
    //   event.files.forEach((element) => {
    //   });

    // event.files == files to upload
    // }

  },
};
</script>
