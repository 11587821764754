<template>
<div class="row justify-center items-center">
      <!-- <q-uploader
        style="max-width: 300px"
        label="Max number of files (10)"
        multiple
        max-files="10"
        :factory="myUploader"
      /> -->
      <q-file
      v-model="files"
      label="Bilder auswählen oder direkt in das Feld ziehen"
      filled
      multiple
      align="center"
      style="width: 100%; max-width: 600px;"
    />
    <q-btn @click="files = []" padding="md"
    >  Liste leeren</q-btn>
    <q-btn @click="myUploader(files)" :disable="files.length > 0 ? false : true"
    padding="md">  upload</q-btn>
</div>
</template>

<script>
import HelperStorage from '@/libs/HelperStorage';
import HelperNotify from '@/libs/HelperNotify';

export default {
  name: 'ImageUpload',
  data() {
    return {
      files: [],
    };
  },
  setup() {
    const {
      probs,
      fStorageUpload,
    } = HelperStorage();
    const {
      notifySuccess,
      notifyFailure,
    } = HelperNotify();
    return {
      notifySuccess,
      notifyFailure,
      probs,
      fStorageUpload,
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    myUploader(event) {
      if (this.files.length > 0) {
        console.log(event);
        // this.fStorageUpload('', event[0]);
        event.forEach((element) => {
          console.log(element);
          this.fStorageUpload('', element);
        });
      } else {
        // TODO notify no images selected for upload
        this.notifyFailure('keine Bilder ausgewählt');
        console.log('Es wurden kein Bilder zum hochladen ausgewählt');
      }
    },
  },
};
</script>
