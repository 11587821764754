<template>
<hr>
  <div class="row justify-center">
    <div class="col" style="max-width: 500px">
      <p class="text-h5" align="center">Neuen Termin anlegen</p>
    <q-input outlined :dense="true" placeholder="Titel"
    id="title" type="text" v-model="title" />
    <q-input outlined :dense="true" placeholder="Termin"
     type="datetime-local" v-model="date" hint="Termin"/>
    <q-input outlined :dense="true" placeholder="TerminEnde"
     type="datetime-local" v-model="date_end" hint="Terminende"/>
    <q-input outlined :dense="true" placeholder="Preis"
     type="text" v-model="price" />
    <q-input outlined :dense="true" placeholder="Beschreibung"
     type="textarea" v-model="description" rows="5" cols="25" />
    <q-btn label="create Meeting" type="submit" color="primary"
    v-on:click="fCreateMeeting(title, price, date, date_end, description)" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import HelperMeetings from '@/libs/HelperMeetings';

export default {
  name: 'NewMeeting',
  data() {
    return {
      title: '',
      date: '',
      date_end: '',
      price: '',
      description: '',
    };
  },
  setup() {
    const {
      probs,
      fOrderMeetings,
      fCreateMeeting,
    } = HelperMeetings();
    return {
      probs,
      fOrderMeetings,
      fCreateMeeting,
    };
  },
  computed: {
    ...mapState(['meetings']),
  },
  watch: {
    meetings() {
      this.title = '';
      this.date = '';
      this.date_end = '';
      this.price = '';
      this.description = '';
    },
    date(newVal) {
      this.date_end = moment(newVal).add(1, 'hour').format('YYYY-MM-DDTHH:mm');
    },
  },

};
</script>

<style>

</style>
