<template>
    <div class="gallery" >
        <div style="background:#c2dd5d; padding: 1rem">
            <p class="text-center text-h5">Bilder</p>
        </div>
        <div class="gallery" style="background:#FBFFEB">
            <ImageUpload v-if="auth"/>
            <ImageGallery />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import ImageGallery from '@/components/ImageGallery.vue';
import ImageUpload from '@/components/ImageUpload.vue';

// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Gallery',
  components: {
    ImageGallery,
    ImageUpload,
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    // myUploader(event) {
    //   event.files.forEach((element) => {
    //   });

    // event.files == files to upload
    // }

  },
};
</script>
