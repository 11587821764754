/* eslint-disable camelcase */
import { reactive } from 'vue';
import { API } from 'aws-amplify';
import { useStore } from 'vuex';
import { createMeeting, deleteMeeting, updateMeeting } from '@/graphql/mutations';
import { listMeetings } from '@/graphql/queries';
import HelperNotify from '@/libs/HelperNotify';

const {
  notifySuccess,
  notifyFailure,
} = HelperNotify();

export default function () {
  const store = useStore();
  const probs = reactive({
  });
  // function validateUsername() {

  // }

  // function validatePassword() {

  // }

  // call this when user log in
  function fOrderMeetings(meetings) {
    console.log('sort Meetings');
    const orderedMeetings = meetings.sort((a, b) => a.date - b.date);
    return orderedMeetings;
  }

  async function fGetMeetings() {
    store.commit('SET_LOADING');
    await API.graphql({
      query: listMeetings,
      authMode: 'AWS_IAM',
    })
      .then((val) => {
        store.commit('SET_MEETINGS', val.data.listMeetings.items);
        store.commit('REMOVE_LOADING');
        return val;
      })
      .catch((err) => {
        notifyFailure('Fehler beim abrufen der Terminliste');
        console.log(err);
      });
    // order ASC on date // TODO in watch function auf meetings packen
  }

  async function fCreateMeeting(title, price, idate, idate_end, description, publish = false,
    registration_close = false) {
    if (!idate || !title || !price || !description || !idate_end) {
      // notify Es müssen alle Felder befüllt sein
      notifyFailure('Es sind nicht alle erforderlichen Felder befüllt');
      return;
    }
    const date = new Date(idate).getTime();
    const date_end = new Date(idate_end).getTime();
    const meeting = {
      date, title, price, description, date_end, publish, registration_close,
    };
    console.log(meeting);
    API.graphql({
      query: createMeeting,
      variables: { input: meeting },
    })
      .then((val) => {
        store.commit('ADD_MEETING', val.data.createMeeting);
        // notify Termin wurde hinzugefügt
        notifySuccess('Termin wurde erfolgreich angelegt');
        console.log(val);
      })
      .catch((err) => {
        // notify Termin konnte nicht angelegt werden
        notifyFailure('Unbekannter Fehler beim anlegen des Termin');
        console.log(err);
      });
  }

  async function fUpdateMeeting(meetingUpdateData) {
    if (!meetingUpdateData.id || !meetingUpdateData.date || !meetingUpdateData.title
      || !meetingUpdateData.price || !meetingUpdateData.description
      || !meetingUpdateData.date_end || meetingUpdateData.publish === undefined
      || meetingUpdateData.registration_close === undefined) {
      // notify Es müssen alle Felder befüllt sein
      notifyFailure('Es sind nicht alle erforderlichen Felder befüllt');

      console.log('irgendwas ist leer');
      console.log(meetingUpdateData);
      return;
    }
    const date = new Date(meetingUpdateData.date).getTime();
    const date_end = new Date(meetingUpdateData.date_end).getTime();
    const meeting = {
      id: meetingUpdateData.id,
      date,
      date_end,
      title: meetingUpdateData.title,
      price: meetingUpdateData.price,
      description: meetingUpdateData.description,
      publish: meetingUpdateData.publish,
      registration_close: meetingUpdateData.registration_close,
    };
    console.log(meeting);
    API.graphql({
      query: updateMeeting,
      variables: { input: meeting },
    })
      .then((val) => {
        // notify Termin wurde aktualisiert
        notifySuccess('Termin wurde aktualisiert');
        fGetMeetings();
        console.log(val);
      })
      .catch((err) => {
        // notify Termin konnte nicht aktualisiert werden
        notifyFailure('Termin konnte nicht aktualisiert werden');
        console.log(err);
      });
  }

  async function fDeleteMeeting(id) {
    const meeting = { id };
    await API.graphql({
      query: deleteMeeting,
      variables: { input: meeting },
    })
      .then((val) => {
        store.commit('REMOVE_MEETING', id);
        // notify Termin wurde gelöscht
        notifySuccess('Termin wurde erfolgreich entfernt');
        console.log(val);
      })
      .catch((err) => {
        console.log(err);
        // notify Termin konnte nicht gelöscht werden
        notifyFailure('Unbekannter Fehler beim entfernen des Termins');
      });
  }

  function fGetDateHuman(date) {
    const newDate = new Date(date);
    return newDate.toLocaleString('de-de', {
      weekday: 'long', year: '2-digit', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
    });
    // toLocaleString('de-de', { month: 'long' })();
  }
  function fGetDateHumanDay(date) {
    const newDate = new Date(date);
    return newDate.toLocaleString('de-de', {
      weekday: 'long', year: '2-digit', month: 'numeric', day: 'numeric',
    });
    // toLocaleString('de-de', { month: 'long' })();
  }
  function fGetDateHumanTime(date) {
    const newDate = new Date(date);
    return newDate.toLocaleString('de-de', {
      hour: 'numeric', minute: 'numeric',
    });
    // toLocaleString('de-de', { month: 'long' })();
  }
  /* eslint-disable no-undef */
  return {
    probs,
    fOrderMeetings,
    fCreateMeeting,
    fGetMeetings,
    fDeleteMeeting,
    fUpdateMeeting,
    fGetDateHuman,
    fGetDateHumanDay,
    fGetDateHumanTime,
  };
  /* eslint-enable no-undef */
}
