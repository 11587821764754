// import { createApp } from 'vue';
// import App from './App.vue';
// import './registerServiceWorker';
// import router from './router';
// import store from './store';

// createApp(App).use(Quasar, quasarUserOptions).use(Quasar,
// quasarUserOptions).use(store).use(router).mount('#app');
// import VueApollo from '@vue/apollo-option';

// Quasar components

import { Auth, Amplify } from 'aws-amplify';

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';;

import awsExports from './aws-exports';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import quasarUserOptions from './quasar-user-options';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Quasar, {
  plugins: {
    Notify,
  },
  config: {
    notify: {
      position: 'top-left',
    /* look at QuasarConfOptions from the API card */ },
  },
});
app.use(quasarUserOptions);

app.config.productionTip = false;

Amplify.configure(awsExports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
// Amplify.configure({
//   Storage: {
//     bucket: 's3 bucket', // REQUIRED -  Amazon S3 bucket
//     region: 'XX-XXXX-X', // OPTIONAL -  Amazon service region
//   },
// });
// next auth statements are only for testing
Auth.currentCredentials()
  .then((d) => console.log('data: ', d))
  .catch((e) => console.log('error: ', e));
Auth.currentAuthenticatedUser()
  .then((d) => console.log('data2: ', d))
  .catch((e) => console.log('error', e));

// PrimeVue components
// app.component('Notify', Notify);

app.mount('#app');
