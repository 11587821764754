import { reactive } from 'vue';
import { Storage } from 'aws-amplify';
import Promise from 'bluebird';
import HelperNotify from '@/libs/HelperNotify';

const {
  notifySuccess,
  notifyFailure,
} = HelperNotify();

// import { useStore } from 'vuex';

export default function () {
  //  const store = useStore();
  const probs = reactive({
  });

  async function fStorageUpload(path = '', element) {
    Storage.put((path === '') ? element.name : `${path}/${element.name}`, element, {
      resumable: true,
      completeCallback: (val) => {
        notifySuccess('Bilder erfolgreich hochgeladen');
        console.log(val);
      },
      progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err) => {
        notifyFailure('unbekannter Fehler beim upload');
        console.log(err);
      },
    });
    // order ASC on date // TODO in watch function auf meetings packen
  }

  async function fDeleteStorageItem(key) {
    return Storage.remove(key)
      .then(notifySuccess('Bild wurde erfolgreich entfernt'))
      .catch((err) => {
        // notify Fehler beim abrufen der Storage Urls
        notifyFailure('Fehler beim löschen der Datei');
        console.log(err);
      });
  }

  async function fGetStorageList(path = '') {
    return Storage.list(path) // for listing ALL files without prefix, pass '' instead
      .catch((err) => {
        // notify Fehler beim Abrufen der Storage Liste'
        notifyFailure('Fehler beim abrufen der Dateiliste');
        console.log(err);
      });
  }

  async function fGetStorageItemUrl(key) {
    return Storage.get(key)
      .catch((err) => {
        // notify Fehler beim abrufen der Storage Urls
        notifyFailure('Fehler beim abrufen der Bild-Urls');
        console.log(err);
      });
  }

  async function fGetStorageItemUrlsAsList(path = '') {
    // returns s3obj with added url prob
    return this.fGetStorageList(path).then((list) => {
      console.log(list);
      const items = list;
      list.forEach((item, index) => {
        items[index].url = fGetStorageItemUrl(item.key);
      });
      console.log('finally');
      console.log(items);

      return Promise.map(items, (obj) => Promise.props(obj));
      /* Promise.props() iterates an object to find all properties that have promises
      as values and uses Promise.all() to await all those promises and it returns
      a new object with all the original properties, but the promises replaced by the
      resolved values. Since we have an array of objects, we use Promise.map() to iterate
      and await the whole array of those.
    */
    });
  }

  /* eslint-disable no-undef */
  return {
    probs,
    fStorageUpload,
    fGetStorageList,
    fGetStorageItemUrl,
    fGetStorageItemUrlsAsList,
    fDeleteStorageItem,
  };
  /* eslint-enable no-undef */
}

// getImages() {
//     Storage.list('') // for listing ALL files without prefix, pass '' instead
//       .then((result) => {
//         console.log(result);
//         result.forEach((image) => {
//           Storage.get(image.key)
//             .then((url) => {
//               console.log(url);
//               this.images = [...this.images, url];
//               console.log('images with new one');
//               console.log(this.images);
//             });
//         });
//       })
//       .catch((err) => console.log(err));
//   },
