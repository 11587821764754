/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelmeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      date
      date_end
      title
      price
      registration_close
      publish
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelmeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      date
      date_end
      title
      price
      registration_close
      publish
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelmeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      date
      date_end
      title
      price
      registration_close
      publish
      description
      createdAt
      updatedAt
    }
  }
`;
