import { createStore } from 'vuex';

export default createStore({
  state: {
    meetings: {},
    auth: false,
    loading: true,
  },
  getters: {
    getMeetings: (state) => state.meetings,
    getAuthenticated: (state) => state.auth,
  },
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    REMOVE_AUTHSTATE(state) {
      state.auth = false;
    },
    SET_LOADING(state) {
      state.loading = true;
    },
    REMOVE_LOADING(state) {
      state.loading = false;
    },
    SET_MEETINGS(state, meetings) {
      state.loading = true;
      state.meetings = meetings.sort((a, b) => a.date - b.date);
      state.loading = false;
    },
    ADD_MEETING(state, meeting) {
      state.loading = true;
      state.meetings = [...state.meetings, meeting];
      state.meetings.sort((a, b) => a.date - b.date);
      state.loading = false;
    },
    REMOVE_MEETING(state, id) {
      state.loading = true;
      state.meetings.splice(state.meetings.map((e) => e.id).indexOf(id), 1);
      state.meetings.sort((a, b) => a.date - b.date);
      state.loading = false;
    },
  },
  actions: {
  },
  modules: {
  },
});
