import './styles/quasar.sass';
// eslint-disable-next-line import/extensions
import lang from 'quasar/lang/de.js';
import '@quasar/extras/material-icons/material-icons.css';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
  },
  lang,
};
