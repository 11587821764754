import { ref } from 'vue';

export default function () {
  // eslint-disable-next-line prefer-const
  let registration = ref(null);
  const updateExists = ref(false);
  const refreshing = ref(false);

  function fUpdateAvailable(event) {
    console.log('fUpdateAvailable');
    console.log(updateExists);
    registration.value = event.detail;
    updateExists.value = true;
    console.log(updateExists);
  }

  function fPwaListener() {
    console.log('run fPwaListener');
    document.addEventListener('swUpdated', fUpdateAvailable, { once: true });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      console.log('eventListenerControllerchange');
      console.log(refreshing.value);
      console.log(refreshing);
      if (refreshing.value) return;
      console.log('eventListenerSetRefreshing');
      refreshing.value = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  function fRefreshApp() {
    updateExists.value = false;
    console.log('refreshApp');
    console.log(updateExists);
    console.log(registration);
    console.log(registration.value);
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration.value || !registration.value.waiting) return;
    // Send message to SW to skip the waiting and activate the new SW
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
  }

  return {
    registration,
    updateExists,
    refreshing,
    fPwaListener,
    fUpdateAvailable,
    fRefreshApp,
  };
}
