<template>
  <div class="q-pa-md" style="min-width: 100%">
      <q-btn v-if="auth"
      flat class="text-red" icon="edit" label="Bearbeiten aktivieren um Bilder zu löschen"
      @click="edit = !edit"/><br/>
      <a v-if="edit" class="text-red text-h5">Vorsicht, Bilder werden direkt entfernt,
        es erfolgt keine weitere Sicherheitsabfrage!</a>
    <div class="q-col-gutter-md row items-start">
      <template v-for="(image, index) in images" :key="index">
      <div class="col-md-2 col-xs-4">
        <q-img :src="image.url" :fit="'contain'"
        spinner-color="primary" loading="lazy"
        style="max-width: 300px; height: 150px;"
        @click="edit ? '' : imageFullscreen(index)"
        >
        <div style="padding:0px" v-if="edit"><q-btn
            flat round  class="text-red" icon="delete"
            @click="deleteImage(image)"/></div>
        </q-img>
      </div>
      </template>
    </div>
  </div>

  <!-- Fullscreen View-->
  <q-dialog
      v-model="imageFullscreenState"
      full-height
      full-width
    >
    <q-carousel
      v-model="FullscreenImageIndex"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      control-color="pink"
      infinite
      navigation
      arrows
      class= "text-black"
      style="background:#FBFFEB; position: relative; z-index:2"
    >
      <template v-for="(image, index) in images" :key="index">
        <q-carousel-slide :name="`${index}`"
          class="column no-wrap flex-center q-pa-none"
          >
          <q-img
          class="shadow-7"
          :src="`${image.url}`"
          :fit="'contain'" />
        </q-carousel-slide>
      </template>
    </q-carousel>
    <q-btn icon="close" flat round dense v-close-popup
    style="position:absolute; z-index:3; top:25px; right:28px; pointer-events: all" />
    </q-dialog>
</template>

<script>
import { mapState } from 'vuex';
import HelperStorage from '@/libs/HelperStorage';

export default {
  name: 'ImageGallery',
  data() {
    return {
      images: [],
      edit: false,
      imageFullscreenState: false,
      FullscreenImageIndex: '',
    };
  },
  setup() {
    const {
      probs,
      fStorageUpload,
      fGetStorageList,
      fGetStorageItemUrl,
      fGetStorageItemUrlsAsList,
      fDeleteStorageItem,
    } = HelperStorage();
    return {
      probs,
      fStorageUpload,
      fGetStorageList,
      fGetStorageItemUrl,
      fGetStorageItemUrlsAsList,
      fDeleteStorageItem,
    };
  },
  created() {
    this.setViewport();
    this.getImages();
  },
  computed: {
    ...mapState(['meetings', 'auth']),
  },
  methods: {
    setViewport() {
      if (document.documentElement.clientWidth >= 960) {
        this.isMobile = false;
        return;
      }
      this.isMobile = true;
    },
    onResize(event) {
      this.setViewport();
      console.log('window has been resized', event);
    },
    getImages() {
      this.fGetStorageItemUrlsAsList().then((list) => {
        console.log('beforeGetStorage');
        console.log(list);
        const listWithoutFolders = [];
        list.forEach((item) => {
          // mapping to sort out all Folders => files with sizes of 0
          if (item.size > 0) {
            listWithoutFolders.push(item);
          }
        });
        this.images = listWithoutFolders;
        console.log('getStorage');
        console.log(this.images);
        console.log(this.images[0].url);
      });
    },
    deleteImage(img) {
      console.log(img);
      this.fDeleteStorageItem(img.key).then((val) => {
        console.log(val);
        this.getImages();
      });
    },
    imageFullscreen(index) {
      this.FullscreenImageIndex = index.toString();
      this.imageFullscreenState = true;
    },
  },
  watch: {
    FullscreenImageIndex(val) {
      console.log(val);
    },
  },
  mounted() {
  // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
  // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
