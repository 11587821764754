<!-- eslint-disable max-len */ -->
<template>
  <!-- <q-card class="my-card"
  >
    <div class="overflow-hidden bg-white-alpha-70 shadow-5"
    style="width:50rem; margin:1rem; border-radius: 2rem 1rem 3rem 1rem;"
    :style="isMobile ? '' : rotationByIndex(index)">
        <div class="flex flex-wrap border-bottom-1 align-items-center">
            <div class="flex-initial flex-none align-items-center justify-content-center
            font text-base m-2 px-5 border-round">
            {{ fGetDateHuman( item.date ) }}</div>
            <div class="flex-initial flex-grow-1 align-items-center justify-content-center
            font-bold  text-base m-2 px-5 border-round">
            {{ item.title }}</div>
            <div class="flex-initial flex-none align-items-center justify-content-center
            font-bold  text-base m-2 px-5 border-round">
            {{ item.price }}€</div>
              <Button
              v-if="isAuth"
              class="p-button-raised button-sm p-button-danger"
              type="Button"
              icon="pi pi-trash"
              v-on:click="fDeleteMeeting(item.id, index)" />
        </div>
        <div class="flex align-items-center">
            <div class="flex-initial flex-grow-1 align-items-center justify-content-center
            font m-2 px-5 border-round">
            {{ item.description }} </div>
        </div>
    </div>
  </q-card> -->
      <div class="row justify-center items-center" v-for="(item, index) in meetings" :key="item.id">
        <q-card v-if="(((item.date > Date.now()) && item.publish) || (item.date > Date.now()) && auth)"
          class="my-card"
          style="margin:1rem; border-radius: 2rem 1rem 3rem 1rem; position:relative; z-index:2"
          :style="[isMobile ? 'width:80rem' : rotationByIndex(index) + ';width:50rem', item.publish ? 'background: #c2dd5d' : 'background: #cccc']">
          <q-card-section class="text-h5" align="center">
            {{ item.title }}
          </q-card-section>
          <q-separator />

          <q-card-section horizontal>
            <q-card-section>
              {{ item.description }}
            </q-card-section>
          </q-card-section>
          <q-img src="../assets/nicht_buchbar.png"
            style="position:relative; z-index:3; width: 160px; margin-top:-70px"
            v-if="item.registration_close">
          </q-img>
          <q-separator />

          <q-card-actions class="text-body-1">
            <q-btn flat round icon="event" />
            <q-btn flat>
              {{ fGetDateHumanDay( item.date ) }}
            </q-btn>
            <q-btn flat>
              {{ fGetDateHumanTime( item.date ) }} -
              {{ fGetDateHumanTime( item.date_end ) }} Uhr
            </q-btn>
            <q-btn flat>
              {{item.price}}€
            </q-btn>
            <q-space />
            <q-btn v-if="auth"
            flat round class="text-grey" :icon="item.publish ? 'visibility' : 'visibility_off'"
            @click="togglePublishState(item)"/>

            <q-btn v-if="auth"
            flat round class="text-grey" icon="edit"
            @click="meetingUpdateState ? '' : meetingUpdateView(item)"/>

            <q-btn v-if="auth"
            flat round class="text-red" icon="delete"
            @click="fDeleteMeeting(item.id)"/>

          </q-card-actions>
        </q-card>
        <!-- eslint-disable-next-line vue/no-parsing-error -->
        <q-dialog
              v-model="meetingUpdateState"
            >
            <div class="row justify-center" style="background:#FBFFEB">
              <div class="col" style="width: 650px">
              <p class="text-h5" align="center">Termin updaten</p>
              <q-input outlined :dense="true" placeholder="Titel"
              id="title" type="text" v-model="meetingUpdateData.title" />
              <q-input outlined :dense="true" placeholder="Termin"
              type="datetime-local" v-model="meetingUpdateData.date" hint="Termin"/>
              <q-input outlined :dense="true" placeholder="TerminEnde"
              type="datetime-local" v-model="meetingUpdateData.date_end" hint="Terminende"/>
              <q-input outlined :dense="true" placeholder="Preis"
              type="text" v-model="meetingUpdateData.price" />
              <q-input outlined :dense="true" placeholder="Beschreibung"
              type="textarea" v-model="meetingUpdateData.description" rows="5" cols="25" />
              <q-btn v-if="!meetingUpdateData.publish"
              label="Termin versteckt" style="width:100%"
              v-on:click="meetingUpdateData.publish = !meetingUpdateData.publish"/>
              <q-btn v-if="meetingUpdateData.publish"
              label="Termin veröffentlicht" style="width:100%"
              v-on:click="meetingUpdateData.publish = !meetingUpdateData.publish"/>
              <q-btn v-if="meetingUpdateData.registration_close"
              label="Buchung geschlossen" style="width:100%"
              v-on:click="meetingUpdateData.registration_close = !meetingUpdateData.registration_close"/>
              <q-btn v-if="!meetingUpdateData.registration_close"
              label="Buchung offen" style="width:100%"
              v-on:click="meetingUpdateData.registration_close = !meetingUpdateData.registration_close"/>

              <q-btn label="update" type="submit" color="primary"

              registration_close
              v-on:click="meetingUpdate(meetingUpdateData)" />
              </div>
            </div>
        </q-dialog>
        </div>
</template>

<script>
import { API } from 'aws-amplify';
import { mapState } from 'vuex';
import moment from 'moment';
import { onCreateMeeting } from '@/graphql/subscriptions';
import HelperMeetings from '@/libs/HelperMeetings';

export default {
  name: 'ListMeetings',
  data() {
    return {
      isMobile: false,
      meetingUpdateState: false,
      meetingUpdateData: {},
    };
  },
  setup() {
    const {
      probs,
      fOrderMeetings,
      fCreateMeeting,
      fGetMeetings,
      fDeleteMeeting,
      fUpdateMeeting,
      fGetDateHuman,
      fGetDateHumanDay,
      fGetDateHumanTime,
    } = HelperMeetings();
    return {
      probs,
      fOrderMeetings,
      fCreateMeeting,
      fGetMeetings,
      fDeleteMeeting,
      fUpdateMeeting,
      fGetDateHuman,
      fGetDateHumanDay,
      fGetDateHumanTime,
    };
  },
  created() {
    this.subscribe();
    this.setViewport();
    this.fGetMeetings();
  },
  computed: {
    ...mapState(['meetings', 'auth']),
  },
  methods: {
    subscribe() {
      API.graphql({ query: onCreateMeeting, authMode: 'API_KEY' })
        .subscribe({
          next: (eventData) => {
            const meeting = eventData.value.data.onCreateMeeting;
            if (this.probs.meetings.some((item) => item.date === meeting.date)) return;
            // remove duplications
            this.probs.meetings = [...this.meetings, meeting];
            console.log('subscribeOnMeeting');
          },
        });
    },
    rotationByIndex: (index) => {
      /* eslint-disable operator-linebreak */
      const rotation =
        // index % 2 === 0
        //   ? 'transform: rotate(-0.75deg)'
        //   : 'transform: rotate(0.75deg)';
        index % 2 === 0
          ? 'margin-left: -5rem'
          : 'margin-right: -5rem';
      return rotation;
      /* eslint-disable operator-linebreak */
    },
    // eslint-disable-next-line camelcase
    meetingUpdateView(item) {
      // .id, item.title, item.price, item.date, item.date_end, item.description, item.publish, item.registration_close
      console.log(item.id, item.date, item.date_end, item.registration_close);
      // const date = moment.utc(idate).add(1, 'hour').format('YYYY-MM-DDTHH:mm');
      const date = moment(item.date).format('YYYY-MM-DDTHH:mm');
      // eslint-disable-next-line camelcase
      // const date_end = moment.utc(idate_end).add(1, 'hour').format('YYYY-MM-DDTHH:mm');
      // eslint-disable-next-line camelcase
      const date_end = moment(item.date_end).format('YYYY-MM-DDTHH:mm');

      console.log(item.date, item.date_end);
      this.meetingUpdateData = {
        id: item.id,
        title: item.title,
        price: item.price,
        date,
        date_end,
        publish: item.publish,
        description: item.description,
        registration_close: item.registration_close,
      };
      this.meetingUpdateState = true;
      // this.meetingUpdate(this.meetingUpdateData);
    },
    togglePublishState(item) {
      this.meetingUpdateData = {
        id: item.id,
        title: item.title,
        price: item.price,
        date: item.date,
        date_end: item.date_end,
        publish: !item.publish,
        description: item.description,
        registration_close: item.registration_close,
      };
      this.meetingUpdate(this.meetingUpdateData);
    },
    meetingUpdate(meetingUpdateData) {
      this.fUpdateMeeting(meetingUpdateData).then((val) => {
        console.log(val);
        this.meetingUpdateState = false;
      });
    },
    setViewport() {
      if (document.documentElement.clientWidth >= 960) {
        this.isMobile = false;
        return;
      }
      this.isMobile = true;
    },
    onResize(event) {
      this.setViewport();
      console.log('window has been resized', event);
    },
  },
  mounted() {
  // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
  // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
