<template>
    <q-layout view="lHh Lpr fff" style="background:#FBFFEB">
      <q-header elevated style="background:#007c97">
        <q-toolbar style="height:5rem">
          <q-toolbar-title class="title text-h2 text-center justify-center"
          style="margin-right:-120px">
            Künstlerkinder
          </q-toolbar-title>
          <img style="height:100%" src="./assets/LogoWithoutText.png" class="gt-xs">
          <!-- <q-btn flat round dense icon="menu" class="q-mr-sm mobile-only" /> -->
        </q-toolbar>
        <q-tabs>
          <q-route-tab name="Startseite" label="Home" to="/" />
          <q-route-tab name="Bilder" label="Bilder" to="/gallery" />
          <q-route-tab name="About" label="Über Mich" to="/about" />
        </q-tabs>
      </q-header>
      <q-page-container style="background:#FBFFEB">

        <router-view></router-view>
        <!-- Login -->
        <q-dialog v-model="login">
          <q-card class="bg-white text-white">
          <amplify-authenticator>
            <amplify-sign-out></amplify-sign-out>
          </amplify-authenticator>
          </q-card>
        </q-dialog>

        <!-- pwa sw update -->
        <q-dialog v-model="updateExists">
          <div class="row justify-center items-center q-pa-xl" style="background:#FBFFEB">
            <div class="col-12 text-h5" align="center"> Die App wurde aktualisiert </div>
            <q-btn class="col-12" @click="fRefreshApp" padding="lg">
              Jetzt neu laden
            </q-btn>
          </div>
        </q-dialog>

        <!-- cookie consent banner -->
          <q-dialog seamless v-model="cookie" position="bottom">
            <q-card style="width: 350px; background:#fab03f">
              <q-card-section class="row items-center no-wrap">
                <div>
                  <div class="text-weight-bold">Unsere Tools von Drittanbietern verwenden Cookies,
                    die für die Funktionalität unserer Seite notwendig und erforderlich sind,
                    um die im Datenschutz erläuterten Zwecke zu erreichen.</div>
                </div>
              </q-card-section>
              <q-card-actions align="around">
                <q-btn @click="CookieAccept()" v-close-popup flat>Verstanden</q-btn>
                <q-btn to="/datenschutz" flat>Datenschutz</q-btn>
              </q-card-actions>
            </q-card>
          </q-dialog>

        <!-- Footer -->
        <q-footer style="background:rgba(0, 0, 0, 0)">
        <div style="height: 150px; overflow: hidden; margin-bottom: -3.5rem;
        background:rgba(0, 0, 0, 0); z-index:2; position:relative" >
          <svg viewBox="0 0 500 150" preserveAspectRatio="none"
          style="height: 100%; width: 100%; filter: drop-shadow( 4px -3px 2px rgba(0, 0, 0, .2))">
            <path
            d="M0.00,49.98 C149.99,150.00 350.85,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style="stroke: none; fill: #ED005B"></path>
          </svg>
        </div>
        <div style="height:5rem; width:100%; background:#ED005B; z-index:3; position:relative">
          <div class="row fit wrap justify-between items-center">
            <div>
              <div class="row">
                <div class="col">
                  <q-btn label="Impressum" color="white" flat to="/impressum"/>
                </div>
                <div class="col">
                  <q-btn label="Datenschutz" color="white" flat to="/datenschutz" />
                </div>
              </div>
            </div>
            <div>
                  <q-btn v-if="!auth" label="Login" color="white" flat @click="login = true" />
                  <q-btn-dropdown v-if="auth" flat color="#ED005B" label="Admin" dropdown-icon="change_history">
                    <q-list>
                      <q-item clickable v-close-popup to="/admin">
                        <q-item-section>
                          <q-item-label>Administration</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="login = true">
                        <q-item-section>
                          <q-item-label>Logout</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
            </div>
          </div>
        </div>
        </q-footer>
      </q-page-container>
    </q-layout>
</template>

<script>
import { ref } from 'vue';
import { mapState } from 'vuex';
import { Hub, Auth } from 'aws-amplify';
import HelperNotify from '@/libs/HelperNotify';
import HelperPwa from '@/libs/HelperPwa';

export default {
  name: 'LayoutDefault',
  data() {
    return {
      login: ref(false),
      cookie: true,
    };
  },
  components: {
  },
  setup() {
    const {
      notifySuccess,
      notifyFailure,
    } = HelperNotify();
    const {
      registration,
      updateExists,
      refreshing,
      fPwaListener,
      fUpdateAvailable,
      fRefreshApp,
    } = HelperPwa();
    return {
      notifySuccess,
      notifyFailure,
      leftDrawerOpen: ref(false),
      registration,
      updateExists,
      refreshing,
      fPwaListener,
      fUpdateAvailable,
      fRefreshApp,
    };
  },
  computed: {
    ...mapState(['auth']),
  },
  mounted() {
    this.fPwaListener();
    // cookie handling
    if (localStorage.getItem('cookie')) {
      this.cookie = localStorage.getItem('cookie');
    }
  },
  beforeMount() {
    Auth.currentCredentials()
      .then((d) => {
        this.$store.commit('SET_AUTH', d.authenticated);
      });
    // Listener for Amplify Auth events
    Hub.listen('auth', (data) => {
      const { payload } = data;
      console.log(payload);
      const { event } = payload;
      console.log(event);
      switch (event) {
        case 'signIn':
          this.$store.commit('SET_AUTH', true);
          console.log('signing in...', event, data);
          this.login = false;
          this.notifySuccess('Erfolgreich eingeloggt');
          break;
        case 'signOut':
          this.$store.commit('REMOVE_AUTHSTATE');
          console.log('signing out...');
          this.login = false;
          this.notifySuccess('Du wurdest abgemeldet');
          break;
        case 'signIn_failure':
          console.log('user sign in failed');
          this.notifyFailure('Anmeldung fehlgeschlagen');
          break;
        default:
          console.log('default');
      }
    });
  },
  methods: {
    CookieAccept() {
      localStorage.setItem('cookie', this.cookie);
    },
  },
};
</script>
<style lang="css">

@font-face {
  font-family: Original Surfer;
  src: url(./fonts/OriginalSurfer-Regular.ttf);
}
@font-face {
  font-family: Licorice;
  src: url(./fonts/Licorice-Regular.ttf);
}
@font-face {
  font-family: Comforter;
  src: url(./fonts/Comforter-Regular.ttf);
}
.title {
  font-family: 'Licorice';
}
</style>
