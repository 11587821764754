import Notify from 'quasar/src/plugins/Notify.js';;

export default function () {
  function notifySuccess(message) {
    Notify.create({
      message,
      icon: 'done',
      color: 'positive',
    });
  }
  function notifyFailure(message) {
    Notify.create({
      message,
      icon: 'warning',
      color: 'negative',
    });
  }
  return {
    notifySuccess,
    notifyFailure,
  };
}
